<template>
    <v-container fill-height>
      <v-row 
      align="center"
      justify="center"
      no-gutters>
        <v-col
          lg="3"
          sm="12"
        >
        <h1>Nothing<br>Left<br>Behind</h1>
        </v-col>
        <v-col
          lg="3"
          sm="12"
        >
        <v-img
          alt="Moving boxes with cat"
          class="shrink ml-12"
          contain
          src="../assets/movingBoxesCat.png"
          transition="scale-transition"
          width="200"
        />
        </v-col>
      </v-row>
      <v-row 
      align="center"
      justify="center"
      no-gutters>
      <v-col
          lg="6"
          sm="12"
          class="justify-right"
        >
        <p>
          Update companies and
          government agencies of your new
          address for free, in just a few clicks.
        </p>
        <v-btn
              dark
              x-large
               to="/invitation"
            >
              Let's begin
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "Welcome",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  methods: {
    onClickButtonNext () {
      this.$emit('clicked', 1)
    },
    onClickButtonPrevious () {
      this.$emit('clicked', -1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
h3 {
  margin: 40px 0 0;
}

h1 {
  font-family: 'Abril Fatface', cursive !important;
  font-weight: normal !important;
  font-size: 5em;
}

.centralText {
  max-width: 50%;
  margin: auto;
}

.stepBox img {
  margin-top: 20px;
}
</style>
