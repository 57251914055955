import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 0,
    invitationVerified: false,
    request: {
      requestUUID: null,
      emailAddress: null,
      emailVerified: false,
      emailUUID: null,
      fullName: null,
      oldAddress: [
        null
      ],
      newAddress: null,
      cc: false,
      organisations: [],
      dob: null,
    },
  },
  mutations: {
    increment (state) {
      state.count++
    },
    setInvitationVerified(state, status) {
      state.invitationVerified = status;
    },
    setRequestUUID(state, uuid) {
      state.request.requestUUID = uuid;
    },
    setEmailAddress(state, emailAddress) {
      state.request.emailAddress = emailAddress;
    },
    setEmailVerification(state, verificationStatus) {
      state.request.emailVerified = verificationStatus;
    },
    setEmailUUID(state, uuid) {
      state.request.emailUUID = uuid;
    },
    setFullName(state, fullName) {
      state.request.fullName = fullName;
    },
    setOldAddress(state, oldAddress) {
      state.request.oldAddress = oldAddress;
    },
    setNewAddress(state, newAddress) {
      state.request.newAddress = newAddress;
    },
    setCC(state, cc) {
      state.request.cc = cc;
    },
    setSelectedOrganisations(state, organisations) {
      state.request.organisations = organisations;
    },
    setDob(state, dob) {
      state.request.dob = dob;
    }
  },
  actions: {
    resetData(context) {
      context.setInvitationVerified(null);
      context.setRequestUUID(null);
      context.setEmailAddress(null);
      context.setEmailVerified(false);
      context.setEmailUUID(null);
      context.setFullName(null);
      context.setOldAddress([
        null
      ]);
      context.setNewAddress(null);
      context.setCC(false);
      context.setSelectedOrganisations([]);
      context.setDob(null);
    }
  },
  modules: {},
  getters: {
    invitationVerified: (state) => {
      return state.invitationVerified;
    },
    requestUUID: (state) => {
      return state.request.requestUUID;
    },
    emailAddress: (state) => {
      return state.request.emailAddress;
    },
    emailVerified: (state) => {
      return state.request.emailVerified;
    },
    emailUUID: (state) => {
      return state.request.emailUUID;
    },
    fullName: (state) => {
      return state.request.fullName;
    },
    oldAddress: (state) => {
      return state.request.oldAddress;
    },
    newAddress: (state) => {
      return state.request.newAddress;
    },
    selectedOrganisations: (state) => {
      return state.request.organisations;
    },
    cc: (state) => {
      return state.request.cc;
    },
    dob: (state) => {
      return state.request.dob;
    },
    request: (state) => {
      return state.request;
    }
  }
});
