<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-app-bar
      app
      elevation=0
      absolute
      :color = $vuetify.theme.themes[theme].background
    >
      <div v-if="currentRoute != 'Home'" class="d-flex align-center height-enforcer">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        <h3 class="my-2"><a href="/">Nothing Left Behind</a></h3>
        <!-- <v-btn
        href=""
        text
      >
        <h3 class="header-btn my-2"><a href="/">Nothing Left Behind</a></h3>
      </v-btn> -->
      </div>

      <v-spacer></v-spacer>
      <div v-if="currentRoute == 'Home'" class="d-flex align-center height-enforcer">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        <h3 class="my-2"><a href="/about">About</a></h3>
        <!-- <v-btn
        href=""
        text
      >
        <h3 class="header-btn my-2"><a href="/">Nothing Left Behind</a></h3>
      </v-btn> -->
      </div>
      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <v-slide-x-transition mode="out-in">
      <router-view/>
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    currentRoute(){
      return this.$route.name
    }
  }
};
</script>
<style>
p {
  font-family: 'Open Sans', sans-serif;
}

h2 {
  /* margin: 40px 0 25px; */
  text-align: center;
}

h3 {
  font-family: 'Abril Fatface', cursive !important;
  font-weight: normal !important;
}

h3 a {
  text-decoration: none;
}

.v-input__slot fieldset {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.v-card {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.header-btn {
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.17em;
}
</style>