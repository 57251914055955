import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/moving",
    name: "Moving",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProcessPages.vue")
  },

  // Better navigation

  {
    path: "/invitation",
    name: "Invitation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InvitationCapture.vue")
  },
  {
    path: "/email",
    name: "Your Email",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/YourEmail.vue"),
    meta: { requiresInvite: true }
  },
  {
    path: "/details",
    name: "Your Details",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CollectDetails.vue"),
    meta: { requiredEmailVerification: true }
  },
  {
    path: "/orgs",
    name: "Select Organisations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OrganizationsSelection.vue"),
    meta: { requiredEmailVerification: true }
  },
  {
    path: "/confirm",
    name: "Confirmation Step",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConfirmationStep.vue"),
    meta: { requiredEmailVerification: true }
  },
  {
    path: "/end",
    name: "Completed",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EndCapture.vue"),
    meta: { requiredEmailVerification: true }
  },
  {
    path: "/*",
    redirect: "/"
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredEmailVerification) && store.getters.emailVerified == false) {
    next("/")
  } 
  else if (to.matched.some(record => record.meta.requiresInvite) && store.getters.invitationVerified == false) {
    next("/")
  } else {
    next()
  }
})

export default router;
