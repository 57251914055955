import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#153B2A',
          secondary: colors.grey.darken1,
          accent: colors.shades.black,
          error: colors.red.accent3,
          black: colors.shades.black,
          background: 'rgba(130, 198, 167, 0.5)', // Not automatically applied
        },
        dark: {
          primary: '#153B2A',
          background: colors.indigo.base, // If not using lighten/darken, use base to return hex
        },
      },
    },
  });
